@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Economica&display=swap');

html {
	font-family: "Nunito", sans-serif;
	font-size: 20px;
}

body {
	min-height: 100vh;
	background-color: #FAFAFA;
}

main {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

a,
a:visited {
	text-decoration: none;
}
.offscreen {
	position: absolute;
	left: -9999px;
}

li {
    list-style-type: none;
}

.hide {
	display: none;
}




td,
th {
	padding: 1rem;
	text-align: left;
}

tr:nth-child(odd) :not(th, button, span) {
	background-color: #f2f2f2;
}

.logo {
	max-width: 7rem;
}
